import { SharedLocale, type SharedLocalizedString } from "../SharedLocale.js";
import { Service, type ServiceWithPage } from "../Service.js";

export const servicesCategoriesPathMap: Record<ServiceWithPage, SharedLocalizedString> = {
	[Service.Integration]: {
		[SharedLocale.cs]: "integrace",
		[SharedLocale.en]: "integration",
		[SharedLocale.pl]: "integracja",
		[SharedLocale.sk]: "integracia",
	},
	[Service.Service]: {
		[SharedLocale.cs]: "podpora",
		[SharedLocale.en]: "support",
		[SharedLocale.pl]: "konsultacje",
		[SharedLocale.sk]: "poradenstvo",
	},
};

export const servicesPathMap = {
	[SharedLocale.cs]: "sluzby",
	[SharedLocale.en]: "services",
	[SharedLocale.pl]: "uslugi",
	[SharedLocale.sk]: "sluzby",
} satisfies SharedLocalizedString;
